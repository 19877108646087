@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.wrapper {
  border-bottom: 1px solid rgba(30, 30, 30, 0.2);
  &:last-child {
    border-bottom: none;
  }

  ul {
    li {
      color: $dark70;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 135%;
    }
  }
}

a {
  text-decoration: none;
  color: $dark90;
  width: 100%;

  &:hover {
    opacity: 0.8;
  }

  @include media-breakpoint-down(545px) {
    text-align: left;
    font-size: 16px;
  }
}

.accordion {
  list-style: none;
}

.accordionItem {
  color: $dark70;
}

.button {
  background-color: transparent;
  color: $dark90;
  border: 0;
  outline: 0;
  padding: 0;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
  margin-top: 23px;
  width: 100%;
  font-weight: 700;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  -ms-tap-highlight-color: transparent;
  tap-highlight-color: transparent;

  @include media-breakpoint-down(545px) {
    text-align: left;
    font-size: 16px;
  }
}

.control {
  font-size: 20px;
}

.answer {
  margin-bottom: 20px;

  p {
    margin: 0px;
    color: $dark70;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    margin-bottom: 10px;
  }

  span {
    font-weight: 700;
  }

  a {
    text-decoration: none;
    font-weight: 700;
  }
}

.answerWrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}

.anserLink {
  color: $dark70;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
}
