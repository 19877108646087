.container {
  color: red;
  margin-top: 64px;
  margin-left: 15px;
  height: calc(100vh - 64px);
  overflow-y: auto;
  padding-right: 15px;

  @media (max-width: 768px) {
    padding-right: 0px;
    margin-left: 0px;
  }
}
