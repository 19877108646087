.container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.leftSide {
  flex: 0 0 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.rightSide {
  flex: 0 0 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
}

.screenLogo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.link {
  text-decoration: none;
  color: #ffff;
  // 5925dc
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkDunbar {
  text-decoration: none;
  color: #f0544f;
  font-weight: 500;
}

.btnLink {
  text-decoration: none;
}
