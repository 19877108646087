@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  padding-top: 20px;
  display: flex;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid $dark30;
}

.text {
  height: 30px;
  display: flex;
  justify-content: space-between;

  a {
    width: auto;
  }
}

.title {
  color: $dark90;
  font-weight: 700;
  font-size: 17px;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}

.textarea {
  background-color: #f5f5f7;
  border: none;
  border-radius: 4px;
  width: 100% !important;
  height: 200px !important;
  padding: 10px;
  margin-top: 15px;
  font-size: 15px;
  @include media-breakpoint-down(545px) {
    font-size: 13px;
  }
}

.description {
  color: $dark70;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;

  @include media-breakpoint-down(545px) {
    font-size: 13px;
  }
}

.charactersLength {
  color: $dark70;
  font-weight: 500;
  font-size: 13px;
  margin-top: 20px;
}

.btn {
  background-color: #f5f5f7 !important;
  color: $dark70 !important;
  justify-content: space-between !important;
  text-transform: none !important;

  box-shadow: none;
}

.habitList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}
