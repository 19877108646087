@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.title {
  height: 30px;
  color: $dark90;
  font-weight: 700;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}

.buttons {
  margin-top: 35px;
  display: flex;
  gap: 5px;
}

.btn {
  cursor: pointer;
  color: white;
  background-color: #f0544f;
  border: none;
  font-weight: 600;
  height: 36px;
  min-width: 108px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 21px;
  width: 100%;

  &:hover {
    background-color: #c04440;
  }
}

.btnGray {
  cursor: pointer;
  color: #34343c;
  background-color: #ebebf0;
  border: none;
  height: 36px;
  min-width: 108px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 21px;
  width: 100%;

  &:hover {
    background-color: #d5d5dc;
  }
}
