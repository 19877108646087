@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.text {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title {
  color: $dark90;
  font-weight: 700;
  font-size: 17px;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}

.infoIcon {
  cursor: pointer;
}

.description {
  margin: 10px 0;
  font-size: 15px;
  font-weight: 500;
  color: #666;
}

.videoContainer {
  border: 1px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  text-align: center;
  cursor: pointer;

  &.dragging {
    background-color: #f0f8ff;
    border-color: #007bff;
  }
}

.videoWrapper {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  flex-direction: column;
}

.uploadText {
  font-size: 22px;
  font-weight: 700;
  color: black;
}

.dragText {
  color: rgba(0, 0, 0, 0.48);
  font-size: 16px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 3px;
}

.uploading {
  font-size: 16px;
  color: #666;
}

.previewVideo {
  width: 100%;
  border-radius: 8px;
}

.maxDurationText {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #666;
}

.hiddenInput {
  display: none;
}

.selectButton {
  margin-top: 5px;
  cursor: pointer;
  color: white;
  background-color: #f0544f;
  border: none;
  font-weight: 600;
  height: 38px;
  min-width: 108px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 18px;
  line-height: 21px;

  &:hover {
    background-color: #c04440;
  }
}
