@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.description {
  color: #5b5b66;
  font-size: 18px;
  font-weight: 500;
}

// .playerWrapper {
//   position: relative;
//   padding-top: 6.25%; /* 720 / 1280 = 0.5625 */
//   border-radius: 55px;
//   background: white;
// }

.reactPlayer {
  background: black;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding-bottom: 0px;

  @include media-breakpoint-down(1190px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include media-breakpoint-down(1053px) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-down(610px) {
    grid-template-columns: 1fr;
  }
}
