@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.hiddenInput {
  display: none;
}

.container {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid $dark30;
}

.text {
  display: flex;
  justify-content: space-between;
}

.title {
  color: $dark90;
  font-weight: 700;
  font-size: 17px;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}

.description {
  color: $dark70;
  font-weight: 500;
  font-size: 15px;

  @include media-breakpoint-down(545px) {
    font-size: 13px;
  }
}

.photoContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  background-color: #f5f5f7;
  color: $dark70;
  font-weight: 500;
  height: 200px;
  width: 200px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
}

.photoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.additionalText {
  color: $dark70;
  font-weight: 500;
}

.upload-image__description {
  font-size: 14px;
  color: $dark70;
  margin-top: 10px;
}

.upload-image__btn-group {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.upload-image__btn-group-text {
  cursor: pointer;
}

.upload-image__btn-group-text_red {
  color: red;
}

.upload-image__input {
  display: none;
}
