.logo {
  height: 40px;
  width: auto;
  margin-bottom: 8px;
  margin-top: 8px;
}

.btn {
  cursor: pointer;
  color: white;
  background-color: #f0544f;
  border: none;
  font-weight: 600;
  height: 36px;
  min-width: 108px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 21px;

  &:hover {
    background-color: #c04440;
  }
}
