@import 'src/styles/variables.scss';

.btn {
  text-transform: none;
  font-size: 18px;
  display: flex;
  gap: 5px;
  &:hover {
    background-color: $ghost;
  }
}

.link {
  text-decoration: none;
}
