@import 'src/styles/variables.scss';

.container {
  padding-top: 20px;
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    height: 350px;
    padding-top: 0px;
  }
}

.btns {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  // align-items: center;
}

.members {
  cursor: pointer;
  border: 1px solid #c9c9d5;
  border-radius: 4px;
  height: 31px;
  width: 26px;

  :hover {
    opacity: 0.7;
  }
}

.img {
  width: 24px;
  height: 24px;
}

.iconsWrapper {
  margin-top: 11px;
  cursor: pointer;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    display: none;
  }
}

.info {
  max-width: 280px;
  @media (max-width: 768px) {
    margin-top: 220px;
    margin-left: 20px;
    max-width: 100%;
  }
}

.linkTitle {
  color: rgb(22, 24, 35);
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

.bio {
  text-align: left;
  color: rgb(22, 24, 35);
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  margin-top: 10px;
  max-width: 600px;
}

.title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: #161823;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    color: white;
    font-size: 19px;
    margin-bottom: 0px;
  }
}

.name {
  font-size: 15px;
  font-weight: 500;
  color: $dark100;
}

.btn {
  width: 150px;
  cursor: pointer;
  color: white;
  background-color: #f0544f;
  font-weight: 600;
  border: none;
  height: 36px;
  min-width: 108px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 21px;

  &:hover {
    background-color: #c04440;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    min-width: 95px;
  }
}

.popover {
  display: flex;
  padding: 5px;
}

.containerMiddle {
  display: flex;
  gap: 14px;

  @media (max-width: 768px) {
    display: none;
  }
}

.infoMiddle {
  color: $dark100;
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
  color: #161823;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #161823bf;
    // margin-left: 6px;
  }
}

.bio {
  color: rgb(22, 24, 35);
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  margin-top: 10px;

  @media (max-width: 768px) {
    color: white;
  }
}

.titleMiddle {
  margin-top: 10px;
}

.editProfile {
  color: #f0544f;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  border: none;
  background-color: transparent;
  padding: 0px;

  @media (max-width: 768px) {
    font-weight: 600;
    background-color: #f0544f; /* Muted red color */
    color: #ffffff; /* White text */
    border: none; /* Remove default border */
    border-radius: 12px; /* Rounded edges */
    font-size: 14px; /* Adjust font size */
    font-weight: bold; /* Bold text */
    padding: 14px 20px; /* Top/Bottom and Left/Right spacing */
    cursor: pointer; /* Pointer cursor */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
  }
}
