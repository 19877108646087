@import 'src/styles/variables.scss';

.container {
  display: flex;
  gap: 14px;
}

.info {
  color: $dark100;
  font-size: 14px;
  font-weight: 700;

  span {
    font-weight: 400;
    color: $dark70;
  }
}

.bio {
  width: 800px;

  @media (max-width: 768px) {
    width: 96%;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
  }
}

.title {
  // margin-left: 10px;
  margin-top: 10px;
}
