@import 'src/styles/variables.scss';

.container {
  padding-top: 20px;
  display: flex;
  gap: 20px;
}

.btns {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  // align-items: center;
}

.members {
  cursor: pointer;
  border: 1px solid #c9c9d5;
  border-radius: 4px;
  height: 31px;
  width: 26px;

  :hover {
    opacity: 0.7;
  }
}

.img {
  width: 24px;
  height: 24px;
}

.iconsWrapper {
  margin-top: 11px;
  cursor: pointer;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.info {
  max-width: 250px;
}
.title {
  font-size: 23px;
  font-weight: 700;
  color: #191920;
  line-height: initial;
  color: $dark100;
}

.name {
  font-size: 15px;
  font-weight: 500;
  color: $dark100;
}

.btn {
  background: transparent !important;
  color: #000 !important;
  box-shadow: none !important;
  border: 2px solid #000 !important;
  // margin-right: 30px !important;
  text-transform: none !important;
  gap: 5px !important;
  font-weight: 600 !important;
  padding: 2px 20px 2px 20px !important;

  &:hover {
    opacity: 0.7;
    background: transparent;
    color: #000;
    box-shadow: none;
    border: 1px solid #a6a6b2;
  }
}

.popover {
  display: flex;
  padding: 5px;
}
