.videoModalContent {
  display: grid;
  grid-template-columns: 13% 57% 30%;
  gap: 20px;

  @media (max-width: 1007px) {
    grid-template-columns: 13% 62% 25%;
  }

  @media (max-width: 941px) {
    grid-template-columns: 2% 65% 33%;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    overflow-y: auto;
    height: calc(100vh - 20px);
  }
}

.video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #000;
  overflow: hidden;
  padding: 0px 20px 20px 20px;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.video::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--video-preview-bg);
  background-size: cover;
  background-position: center;
  filter: blur(20px) brightness(0.5);
  z-index: 0;
}

.verticalPlayer {
  position: relative;
  z-index: 1;
  aspect-ratio: 9 / 16;
  max-width: 400px;
  width: 100%;
  background-color: #000;
  overflow: hidden;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.3);

  :global(video),
  :global(.react-player__preview) {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }
}
