@mixin media-breakpoint-down($breakpoint) {
  @media (max-width: $breakpoint - 1px) {
    @content;
  }
}

@mixin text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
