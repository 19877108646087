@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  display: flex;
  flex-direction: column;
}

.description {
  color: $dark70;
  font-weight: 500;
  font-size: 15px;

  @include media-breakpoint-down(545px) {
    font-size: 13px;
  }
}

.or {
  color: #161823;
  font-size: 13px;
  font-weight: 700;
  margin-top: 16px;
}

.members {
  border-bottom: 1px solid #d5d5dc;
}

.title {
  text-align: center;
  width: 100%;
  color: $dark90;
  font-weight: 700;
  font-size: 17px;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}

.btn {
  margin-top: 30px;
  width: 100%;
  cursor: pointer;
  color: white;
  background-color: #f0544f;
  font-weight: 600;
  border: none;
  height: 36px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 21px;

  &:hover {
    background-color: #c04440;
  }
}

.error {
  color: red;
  padding-top: 3px;
  font-size: 14px;
}
