.container {
}

.list {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  gap: 5px 8px;
  overflow-x: auto;
  padding-bottom: 15px;
}
