@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.blockTitle {
  margin-top: 28px;
  margin-bottom: 10px;
  color: $dark90;
  font-weight: 600;
  font-size: 19px;

  @include media-breakpoint-down(545px) {
    font-size: 16px;
  }
}

.container {
  display: flex;
  gap: 20px;
  width: 100%;

  @include media-breakpoint-down(545px) {
    flex-direction: column;
  }
}

.description {
  margin-top: 140px;
  margin-bottom: 10px;
  color: #f1aba7;
  font-weight: 500;
  font-size: 18px;

  @include media-breakpoint-down(545px) {
    font-size: 16px;
  }
}

.block {
  margin-top: 70px;

  @include media-breakpoint-down(545px) {
    margin-top: 20px;
  }
}
