.container {
  color: red;
  margin-top: 64px;
  margin-left: 15px;
  height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 768px) {
    margin-left: 0px;
  }
}
