@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.blockTitle {
  margin-top: 28px;
  margin-bottom: 10px;
  color: $dark90;
  font-weight: 600;
  font-size: 19px;

  @include media-breakpoint-down(545px) {
    font-size: 16px;
  }
}

.content {
  display: flex;
  gap: 30px;

  @include media-breakpoint-down(545px) {
    flex-direction: column;
  }
}

.list {
  list-style: none;
  color: #f1aba7;
  font-weight: 500;
  font-size: 17px;
  padding-left: 0px;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}

.container {
  display: flex;
  gap: 20px;
  width: 100%;
}

.text {
  margin-top: 20px;
  margin-bottom: 10px;
  color: $dark90;
  font-weight: 500;
  font-size: 17px;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}
