@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  background-color: #f5f5f7;
  height: 100vh;
  overflow-y: auto;
  @include media-breakpoint-down(545px) {
    padding-bottom: 60px;
  }
}

.loaderContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  background-color: $white;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 780px;
  position: relative;
  width: calc(100% - 80px);
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;

  @include media-breakpoint-down(545px) {
    width: calc(100% - 30px);
  }
}

.btn {
  margin-top: 30px;
  cursor: pointer;
  color: white;
  width: 100%;
  background-color: #f0544f;
  border: none;
  height: 42px;
  font-weight: 600;
  min-width: 108px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 18px;
  line-height: 21px;

  &:hover {
    background-color: #c04440;
  }
}
