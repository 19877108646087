@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';
.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  ul {
    padding-left: 30px;
  }
}

.blockTitle {
  margin-top: 28px;
  margin-bottom: 10px;
  color: $dark90;
  font-weight: 600;
  font-size: 19px;

  @include media-breakpoint-down(545px) {
    font-size: 16px;
  }
}

.description {
  color: $dark70;
  font-weight: 500;
  font-size: 18px;

  @include media-breakpoint-down(545px) {
    font-size: 16px;
  }
}

.content {
  display: flex;
  gap: 10px;

  @include media-breakpoint-down(545px) {
    flex-direction: column;
  }
}

.bottomContent {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(545px) {
    flex-direction: column;
  }
}
