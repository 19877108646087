.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
  height: 100vh;
  overflow-y: auto;
  background-color: #fff;
}

.title {
  padding-top: 50px;
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.effectiveDate {
  font-weight: bold;
  margin-bottom: 1rem;
}

.intro {
  margin-bottom: 1.5rem;
}

.link {
  color: #0066cc;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.divider {
  margin: 2rem 0;
  border: none;
  border-top: 1px solid #ccc;
}

.subtitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.list {
  padding-left: 1rem;
  margin-bottom: 1.5rem;

  li {
    margin-bottom: 0.5rem;
  }
}
