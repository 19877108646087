@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.title {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #f1aba7;
  font-weight: 500;
  font-size: 18px;

  @include media-breakpoint-down(545px) {
    font-size: 16px;
  }
}

.img {
  @include media-breakpoint-down(545px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
