@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  ul {
    padding-left: 30px;
  }
}

.mainTitle {
  margin-top: 80px;
  margin-bottom: 20px;
  color: $dark90;
  font-weight: 700;
  font-size: 30px;

  @include media-breakpoint-down(545px) {
    font-size: 22px;
  }
}

.title {
  margin-top: 20px;
  margin-bottom: 10px;
  color: $dark90;
  font-weight: 700;
  font-size: 22px;

  @include media-breakpoint-down(545px) {
    font-size: 18px;
  }
}

.blockTitle {
  margin-top: 18px;
  margin-bottom: 10px;
  color: $dark90;
  font-weight: 600;
  font-size: 19px;

  @include media-breakpoint-down(545px) {
    font-size: 16px;
  }
}

.description {
  color: $dark70;
  font-weight: 500;
  font-size: 18px;

  @include media-breakpoint-down(545px) {
    font-size: 16px;
  }
}

.content {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 20px;

  @include media-breakpoint-down(545px) {
    gap: 50px;
  }
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 250px;

  @include media-breakpoint-down(545px) {
    flex-direction: column;
    gap: 40px;
  }
}
