@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.btn {
  cursor: pointer !important;
  color: white;
  background-color: #f0544f;
  border: none;
  font-weight: 600;
  height: 36px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 21px;
  margin-top: 10px;

  &:hover {
    background-color: #c04440;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    min-width: 95px;
  }
}

.title {
  color: $dark90;
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}

.MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}

.wrapperMiddle {
  margin-top: 20px;
  margin-bottom: 20px;
}

.wrapperBottom {
  margin-top: 20px;
  margin-bottom: 20px;
}
