@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  border-bottom: 1px solid $dark30;
}

.wrapper {
  align-items: center;
  padding: 10px 0px;
  display: flex;
  gap: 15px;
}

.titleSelect {
  color: $dark90;
  font-weight: 500;
  font-size: 15px;
}

.text {
  display: flex;
  justify-content: space-between;
  a {
    width: auto;
  }
}

.title {
  color: $dark90;
  font-weight: 700;
  font-size: 17px;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}

.description {
  color: $dark70;
  font-weight: 500;
  font-size: 15px;

  @include media-breakpoint-down(545px) {
    font-size: 13px;
  }
}

.charactersLength {
  color: $dark70;
  font-weight: 500;
  font-size: 13px;
  margin-top: 10px;
}
