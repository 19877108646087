@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.scrollableContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding-bottom: 0px;
  width: 100%;
  // overflow-y: auto;
  // overflow-x: hidden;
  border-radius: 8px;
  padding: 10px;
  scrollbar-width: thin;
  scrollbar-color: #888 #f9f9f9;

  @include media-breakpoint-down(1190px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include media-breakpoint-down(1053px) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-down(610px) {
    grid-template-columns: 1fr;
  }
}

.scrollableContainer::-webkit-scrollbar {
  width: 8px;
}

.scrollableContainer::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.scrollableContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
}

.videoItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.videoPlayer {
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
}

.noVideos {
  text-align: center;
  color: #888;
  font-size: 16px;
  padding: 20px;
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-top: 80px;

  @include media-breakpoint-down(545px) {
    margin-top: 20px;
  }
}
