@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid $dark30;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: $dark90;
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}

.userTitle {
  color: $dark90;
  font-weight: 500;
  font-size: 17px;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}

.desc {
  color: $dark70;
  font-weight: 500;
  font-size: 15px;
  text-align: center;

  @include media-breakpoint-down(545px) {
    font-size: 13px;
  }
}

.userList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 275px;
  overflow-y: auto;
  padding-right: 10px;
}

.userList::-webkit-scrollbar {
  width: 6px;
}
.user {
  display: flex;
  gap: 10px;
  align-items: center;
}

.iconsWrapper {
  button {
    cursor: pointer;
    margin: 0px;
    width: 100%;
    cursor: pointer;
    color: white;
    background-color: #f0544f;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    line-height: 18px;

    &:hover {
      background-color: #c04440;
    }
  }
}

.popover {
  display: flex;
  padding: 5px;
  color: #34343c;
  width: 100%;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.btn {
  margin-top: 30px;
  width: 100%;
  cursor: pointer;
  color: white;
  background-color: #f0544f;
  font-weight: 600;
  border: none;
  height: 36px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 21px;

  &:hover {
    background-color: #c04440;
  }
}

.members {
  border-top: 1px solid #d5d5dc;
  padding-top: 10px;
}
