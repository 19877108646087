@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  ul {
    padding-left: 30px;
  }
}

.title {
  margin-top: 80px;
  margin-bottom: 20px;
  color: $dark90;
  font-weight: 700;
  font-size: 30px;

  @include media-breakpoint-down(545px) {
    font-size: 22px;
  }
}

.isTldr {
  margin-bottom: 0px;
  margin-top: 0px;
  color: $dark90;
  font-weight: 700;
  font-size: 22px;

  @include media-breakpoint-down(545px) {
    font-size: 18px;
  }
}

.isAuction {
  margin-bottom: 0px;
  margin-top: 30px;
  color: $dark90;
  font-weight: 700;
  font-size: 30px;

  @include media-breakpoint-down(545px) {
    font-size: 22px;
  }
}

.isSmallTitle {
  margin-top: 20px;
  margin-bottom: 10px;
  color: $dark90;
  font-weight: 700;
  font-size: 22px;

  @include media-breakpoint-down(545px) {
    font-size: 18px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.listItem {
  color: $dark70;
  font-weight: 500;
  font-size: 18px;

  @include media-breakpoint-down(545px) {
    font-size: 16px;
  }
}
