@import 'src/styles/variables.scss';

.videoWrapper {
  position: relative;
  width: 100%;
}

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99%;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.loaderSpinner {
  width: 48px;
  height: 48px;
  border: 5px solid transparent;
  border-top: 5px solid #e52e2e;
  border-radius: 50%;
  animation: spinArc 0.8s linear infinite;
}

@keyframes spinArc {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.videoContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 400px;
  margin: auto;

  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 0, 0, 0.22);
  //   border-radius: 20px;
  //   pointer-events: none;
  // }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 99%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.15) 60%,
      rgba(0, 0, 0, 0.6) 100%
    );
    border-radius: 20px;
    pointer-events: none;
  }
}

.videoPlayer {
  width: 100%;
  height: 500px;
  object-fit: cover;
  background: black;
  cursor: pointer;
  border-radius: 20px;
}

.videoDetails {
  // position: absolute;
  margin-top: 26px;
  font-size: 14px;
  // text-align: center;
  color: white;
}

.challengeOverlay {
  position: absolute;
  bottom: 15px;
  width: 100%;
  color: white;
  z-index: 2;
  text-align: left;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
  padding-left: 20px;
}

.memberName {
  font-size: 24px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.date {
  font-size: 14px;
  font-weight: 500;
}

.title {
  font-size: 16px;
  font-weight: 600;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 90%;
  margin-bottom: 59px;
}

.btn {
  margin-top: 10px;
  cursor: pointer;
  color: white;
  width: 100%;
  background-color: #f0544f;
  border: none;
  height: 42px;
  min-width: 108px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  line-height: 21px;

  &:hover {
    background-color: #c04440;
  }
}

.videoContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: auto;
}

.btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  font-size: 16px;
  font-weight: 600;
  width: 153px;
}
