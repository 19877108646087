@import 'src/styles/variables.scss';

.container {
  display: flex;
  gap: 14px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.info {
  color: $dark100;
  font-size: 14px;
  font-weight: 700;

  span {
    font-weight: 400;
    color: $dark70;
  }
}

.categoryList {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 300px;
  gap: 10px;
}
