.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f7;
  border-radius: 15px;
  padding: 10px;
  color: black;
}

.cutdown {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.spots {
  color: #000;
  font-size: 14px;
}

.btn {
  background: transparent;
  color: #000;
  box-shadow: none;
  border: 1px solid #a6a6b2;
  cursor: pointer;
  font-weight: 600;
  height: 36px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 21px;

  &:hover {
    opacity: 0.7;
    background: transparent;
    color: #000;
    box-shadow: none;
    border: 1px solid #a6a6b2;
  }

  a {
    color: #000;
    text-decoration: none;
  }
}
