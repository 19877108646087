.commentInputContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.inputWrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.commentInput {
  width: 100%;
  padding: 10px 40px 10px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff;
  }
}

.emojiIcon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #888;
  transition: color 0.3s ease;

  &:hover {
    color: #007bff;
  }
}

.emojiPickerContainer {
  position: absolute;
  bottom: 100%;
  left: 0;
  margin-bottom: 10px;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.postButton {
  text-align: center;
  width: 15%;
  color: white;
  background-color: #f0544f;
  border-radius: 4px;
  font-weight: 600;
  padding: 7px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}
