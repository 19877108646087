@import 'src/styles/variables.scss';

.container {
  display: flex;
  gap: 10px;
  margin-top: 8px;
}

.block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid rgba(22, 24, 35, 0.75);
  padding: 3px 8px;
  color: rgba(22, 24, 35, 0.75);
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  gap: 8px;
  font-weight: 400;
  cursor: pointer;

  &.selected {
    background: #fe2c55;
    color: white;
    border: 1px solid transparent;
  }
}

.selected {
  background: #fe2c55;
  color: white;
  border: 1px solid transparent;
}
