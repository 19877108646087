@import 'src/styles/variables.scss';

.commentsList {
  overflow: scroll;
  overflow-x: hidden;
  height: calc(100vh - 120px);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.isChallengePage {
  height: calc(80vh - 10px);
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
}

.totalComments {
  color: $dark90;
  font-weight: 600;
}

.replyBlock {
  padding-right: 10px;
}
