@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.buttons {
  display: flex;
  gap: 10px;
  margin-top: 30px;
}

.description {
  color: $dark70;
  font-weight: 500;
  font-size: 15px;

  @include media-breakpoint-down(545px) {
    font-size: 13px;
  }
}

.container {
  padding: 20px;
}
