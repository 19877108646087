.container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.scrollableContent {
  max-height: 70vh; /* Adjust as needed */
  overflow-y: auto;
  width: 100%; /* Full width to fit the table */
  padding-right: 10px; /* Optional, to avoid overlap with the scrollbar */
}
