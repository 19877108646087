@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.title {
  color: $dark90;
  font-weight: 700;
  font-size: 17px;
  text-align: center;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}

.charactersLength {
  color: $dark70;
  font-weight: 500;
  font-size: 13px;
  margin-top: 10px;
}

.btn {
  margin-top: 30px;
  cursor: pointer;
  color: white;
  width: 100%;
  background-color: #f0544f;
  border: none;
  height: 42px;
  min-width: 108px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 18px;
  line-height: 21px;

  &:hover {
    background-color: #c04440;
  }
}

.error {
  color: red;
}

.loaderOverlay {
  height: 338px;
  display: flex;
  align-items: center;
  justify-content: center;
}
