@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.link {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.title {
  color: $dark90;
  margin-top: 80px;
  @include media-breakpoint-down(545px) {
    font-size: 22px;
    margin-bottom: 25px;
  }
}
