@import 'src/styles/variables.scss';

.container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.leftSide {
  flex: 0 0 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.form {
  width: 353px;
}

.rightSide {
  flex: 0 0 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $athensGray;
}

.screenLogo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.link {
  text-decoration: none;
  color: $purpleHeart;
  // 5925dc
  font-weight: 500;
}

.linkDunbar {
  text-decoration: none;
  color: $primary100;
  font-weight: 500;
}
