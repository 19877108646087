@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  border-top: 1px solid rgba(22, 24, 35, 0.12);
}

.title {
  margin-top: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: rgba(22, 24, 35, 0.75);
  margin-bottom: 10px;

  @media (max-width: 940px) {
    font-size: 12px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 10px;
  &.scrollable {
    max-height: 320px;
    margin-bottom: 10px;
    overflow-y: auto;
    padding-left: 3px;
    @media (max-width: 545px) {
      padding-left: 8px;
    }
  }
}

.list.scrollable::-webkit-scrollbar {
  width: 8px;
}

.list.scrollable::-webkit-scrollbar-thumb {
  //   background-color: red;
  border-radius: 10px;
}

.warning {
  margin-top: 8px;
  width: 199px;
  box-sizing: border-box; /* Ensures padding/border doesn't increase width */
  word-wrap: break-word; /* Force text to wrap */
  white-space: normal; /* Allow normal text wrapping */
  display: inline-block; /* Prevent stretching */
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: rgba(22, 24, 35, 0.75);
}
