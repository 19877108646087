.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 940px) {
    display: none;
  }
  height: 100%;
  justify-content: end;
  border-top: 1px solid rgba(22, 24, 35, 0.12);
  padding-top: 10px;
}

.linkButton {
  width: 199px !important;
  align-items: center;
  justify-content: center;
}

.btn {
  background: white !important;
  color: #f0544f !important;
  border: 1px solid #f0544f !important;
  height: 42px;
  min-width: 108px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 21px;
  cursor: pointer;
  width: 100%;

  @media screen and (max-width: 940px) {
    display: none;
  }
}

.logo {
  width: 195.5px !important;
  height: 40px;
  width: auto;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-left: 23px;
}
