// @import './src/styles/variables';
// @import 'src/styles/wabb-styles/variables.scss';
// @import 'src/styles/wabb-styles/shadows.scss';

.title {
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.phone-input-container {
  margin-top: 10px;
}

.phone-input {
  margin-top: 4px;
}

.phone-input-error {
  margin-top: 4px;

  input {
    border: 1px solid red !important;
    border-radius: 4px;

    &:focus {
      outline: none !important;
      border: none !important;
    }
  }
}

.required {
  color: #f04438;
}

.react-tel-input {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  position: relative;
  width: 100%;
}

.react-tel-input :disabled {
  cursor: not-allowed;
}

.error-value {
  bottom: -19px;
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-size: 0.75rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  margin-top: 3px;
  margin-left: 3px;
  color: red;
}

.react-tel-input .form-control {
  height: 56px !important;
  border-radius: 4px;
  background: #f5f5f7;
  width: 100%;
  padding: 9px 14px 9px 58px;
  overflow: hidden;
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border: none !important;

  &:hover {
    // background-color: $gray50;
  }

  &:disabled {
    // background-color: $gray50;
    // color: $gray400;
  }

  &:focus {
    outline: none !important;
    border: none !important;
  }
}

.react-tel-input .special-label {
  display: none !important;
}

.react-tel-input .form-control.invalid-number {
  border-color: #f44336 !important;
  background-color: #ffe6f2 !important;
}

.react-tel-input .form-control.invalid-number:focus {
  border-color: #f44336 !important;
  background-color: #ffe6f2 !important;
}

.react-tel-input .form-control.invalid-number + div:before {
  content: 'Error';
  display: none;
  border-color: #f44336 !important;
  background-color: #ffe6f2 !important;
  width: 27px;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border-radius: 3px 0 0 3px;
}

.react-tel-input .flag-dropdown:hover,
.react-tel-input .flag-dropdown:focus {
  cursor: pointer;
}

.react-tel-input .flag-dropdown.open {
  z-index: 2;
}

.react-tel-input input[disabled] + .flag-dropdown:hover {
  cursor: default;
  border-color: #cacaca;
}

.react-tel-input input[disabled] + .flag-dropdown:hover .selected-flag {
  background-color: transparent;
}

.react-tel-input .selected-flag {
  outline: none;
  position: absolute;
  width: 52px;
  height: 100%;
  padding: 0 0 0 11px;
  border-radius: 3px 0 0 3px;
}

.react-tel-input .selected-flag .flag {
  position: absolute;
  top: 50%;
  margin-top: -12px;
}

.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -1px;
  left: 29px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.react-tel-input .selected-flag .arrow.up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.react-tel-input .country-list {
  outline: none;
  top: 47px;
  list-style: none;
  z-index: 10000;
  overflow-y: scroll;
  position: absolute;
  margin: 10px 0 10px -1px;
  background-color: white;
  width: 337px;
  max-height: 220px;
  //   box-shadow: $shadow-md;
  border-radius: 4px;
  //   border: 1px solid $gray300;
}

.react-tel-input .country-list .flag {
  display: inline-block;
  position: absolute;
  left: -29px;
  top: 8px;
}

.react-tel-input .country-list .divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
}

.react-tel-input .country-list .country {
  position: relative;
  padding: 12px 9px 13px 5px;
  background-color: white !important;
}

.react-tel-input .country-list .country :hover {
  background-color: white !important;
}

.react-tel-input .country-list .country .dial-code {
  color: #a4a8b8;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.react-tel-input .country-list > li:hover span {
  color: #5925dc !important;
  background-color: white !important;
}

.react-tel-input .country-list .country.highlight {
  color: #5925dc;
  //   background-image: url('../../../../images/check.png');
  background-color: white;
  background-repeat: no-repeat;
  background-position-x: 315px;
  background-position-y: 15px;
}

.react-tel-input .country-list .flag {
  margin-right: 7px;
  margin-top: 2px;
}

.react-tel-input .country-list .country-name {
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-right: 6px;
  font-family: Inter;
}

.react-tel-input .country-list .search {
  z-index: 2;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 10px 0 6px 10px;
}

.react-tel-input .country-list .search-emoji {
  display: none;
  font-size: 15px;
}

.react-tel-input .country-list .search-box {
  border: 1px solid #cacaca;
  border-radius: 3px;
  font-size: 15px;
  line-height: 15px;
  margin-left: 6px;
  padding: 3px 8px 5px;
  outline: none;
}

.react-tel-input .country-list .search-box:hover {
  border-color: #505050;
}

.react-tel-input .country-list .no-entries-message {
  padding: 7px 10px 11px;
  opacity: 0.7;
}

.react-tel-input .invalid-number-message {
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #fff;
  padding: 0 5px;
  color: #de0000;
}

.react-tel-input .special-label {
  height: 13.2px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: normal;
  position: absolute;
  display: block;
  z-index: 1;
  top: -7px;
  left: 13px;
  line-height: 18px;
  background: white;
  color: rgba(0, 0, 0, 0.54);
  padding: 0 5px;
  font-size: 11px;
  white-space: nowrap;
}

.react-tel-input:focus-within .special-label {
  position: absolute;
  display: block;
  z-index: 1;
  //   color: $color-blue;
  background: white;
  padding: 0 5px;
  white-space: nowrap;
}
