.videoWrapper {
  position: relative;
}

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99%;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.loaderSpinner {
  width: 48px;
  height: 48px;
  border: 5px solid transparent;
  border-top: 5px solid #e52e2e;
  border-radius: 50%;
  animation: spinArc 0.8s linear infinite;
}

@keyframes spinArc {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  gap: 15px;
}

.videoContainer {
  position: relative;
}

.video {
  width: 100%;
  height: 500px;
  object-fit: cover;
  background: black;
  cursor: pointer;
  border-radius: 20px;
}

.uploadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.dateOverlay {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.commentOverlay {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.commentIcon {
  margin-right: 5px;
  font-size: 16px;
}

.challengeOverlay {
  position: absolute;
  bottom: 15px;
  width: 100%;
  color: white;
  z-index: 2;
  text-align: left;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
}

.challengeItem {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.moreItem {
  margin-left: 9px;
  font-size: 12px;
}

.challengeImage {
  width: 13px;
  height: 13px;
  margin-right: 8px;
  margin-left: 8px;
}

.challengeTitle {
  font-size: 12px;
  line-height: 100%;
  margin-left: 9px;
}

.dunbarIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.dunbarIcon {
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.commentsCount {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.topData {
  margin-left: 8px;
}

.circleName {
  font-size: 14px;
  font-weight: 600;
}

.time {
  font-size: 12px;
}

.bottomTitle {
  margin-top: 15px;
  margin-left: 8px;
  font-size: 13px;
  font-weight: 600;
}

.commentsCount {
  font-size: 12px;
}
