@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.scrollableContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding-bottom: 0px;

  @include media-breakpoint-down(1190px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include media-breakpoint-down(1053px) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-down(610px) {
    grid-template-columns: 1fr;
  }
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollableContainer::-webkit-scrollbar {
  width: 8px;
}

.scrollableContainer::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.scrollableContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-top: 80px;

  @include media-breakpoint-down(545px) {
    margin-top: 20px;
  }
}
