@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
}

.wrapper {
  background-color: white;
  border-radius: 30px;

  @include media-breakpoint-down(545px) {
    padding: 0px;
    background-color: transparent;
    border-radius: none;
  }
}

.title {
  color: $dark90;
  margin-bottom: 20px;

  @include media-breakpoint-down(545px) {
    margin-bottom: 10px;
  }
}

.link {
  border-bottom: 1px solid rgba(30, 30, 30, 0.2);
}

.listItem {
  counter-increment: my-awesome-counter;
  padding-top: 20px;
  position: relative;

  &::before {
    content: counter(my-awesome-counter) '.';
    display: block;
    position: absolute;
    right: 1070px;
  }
}

.listWrapper {
  counter-reset: my-awesome-counter;
  list-style: none;
  padding: 0;
}

.descriptionBlock {
  margin-bottom: 20px;
}

.answerText {
  color: $dark70;
}

.listContainerItem {
  list-style-type: disc;
  margin-top: 20px;
}

.listContainer {
  margin-left: 50px;
}

.innerListWrapper {
  list-style-type: circle;
  padding-top: 10px;
}

.innerListItem {
  margin-left: 60px;
}
