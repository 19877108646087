.commentInputContainer {
  position: relative;
  width: 100%;
  gap: 10px;
  margin-top: 15px;
}

.middle {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputWrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.commentInput {
  width: 100%;
  padding: 10px 40px 10px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff;
  }
}

.emojiIcon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #888;
  transition: color 0.3s ease;
  top: 7px;

  &:hover {
    color: #007bff;
  }
}

.emojiPickerContainer {
  position: absolute;
  bottom: 100%;
  left: 0;
  margin-bottom: 10px;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 13px;
  cursor: pointer;
  color: white;
  width: 131px;
  background-color: #f0544f;
  font-weight: 600;
  width: 140px;
  border: none;
  height: 42px;
  min-width: 110px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 15px;
  line-height: 21px;

  &:hover {
    background-color: #c04440;
  }
}

.maxDurationText {
  margin-top: 3px;
  font-size: 13px;
  font-weight: 500;
  color: #666;
}
