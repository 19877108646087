@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  display: flex;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid $dark30;
}

.title {
  color: $dark90;
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  margin-bottom: 10px;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}

.desc {
  color: $dark70;
  font-weight: 500;
  font-size: 15px;
  text-align: center;

  @include media-breakpoint-down(545px) {
    font-size: 13px;
  }
}
