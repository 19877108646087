@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.link {
  color: white;
  text-decoration: none;
}

.title {
  color: $dark90;
  margin-top: 80px;
  @include media-breakpoint-down(545px) {
    font-size: 22px;
    margin-bottom: 25px;
  }
}
