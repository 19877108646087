@import 'src/styles/typography.scss';
@import 'src/styles/variables.scss';

.title {
  margin-top: 10px;
  @include title;
  color: $dark100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.border {
  height: 1px;
  background: $dark30;
  position: relative;
  left: -24px;
  width: 112%;
  margin-top: 10px;
}

.footer {
  margin-top: 15px;
  font-size: 14px;
  color: $dark100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  color: $radicalRed;
  margin-left: 3px;
  cursor: pointer;
}

.btn {
  margin-top: 20px;
  cursor: pointer;
  color: white;
  width: 100%;
  background-color: #f0544f;
  border: none;
  font-weight: 600;
  height: 42px;
  min-width: 108px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 18px;
  line-height: 21px;

  &:hover {
    background-color: #c04440;
  }
}
