@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.title {
  color: $dark90;
  margin-top: 80px;
  @include media-breakpoint-down(545px) {
    font-size: 22px;
    margin-bottom: 25px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  height: 90vh;
  overflow-y: auto;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: calc(100% - 80px);

  @include media-breakpoint-down(545px) {
    margin-left: 20px;
    margin-right: 20px;
    overflow-x: hidden;
    padding-left: 0px;
    padding-right: 0px;
    width: calc(100% - 51px);
  }
}

.faq {
  margin-top: 200px;

  @include media-breakpoint-down(545px) {
    margin-top: 100px;
  }
}

.contactUs {
  color: $dark90;

  a {
    text-decoration: underline;
    color: rgb(156, 156, 237);
  }
}
