@import url('https://fonts.cdnfonts.com/css/general-sans');

// Define variables
$primary-color: #007bff;
$secondary-color: #fff;

html {
  scroll-behavior: smooth;
}

body {
  background-color: $secondary-color;
  margin: 0;
  padding: 0;
}

.main {
  background-color: $secondary-color;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
}
