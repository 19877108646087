@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.btn {
  margin-top: 30px;
  width: 100%;
  cursor: pointer;
  color: white;
  background-color: #f0544f;
  border: none;
  font-weight: 600;
  height: 36px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 21px;

  &:hover {
    background-color: #c04440;
  }
}

.modalContent {
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;

  &::-webkit-scrollbar {
    width: 3px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    // background: #ccc;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    // background: #ccc;
  }
}

.title {
  color: $dark90;
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}

.error {
  color: red;
  padding-top: 3px;
  font-size: 14px;
}
