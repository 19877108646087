// Primary Colors
$primary100: #f0544f;
$primary20: #fcdddc;
$primary30: #fdeeed;

// Secondary Colors
$dark100: #191920;
$dark90: #34343c;
$dark70: #5b5b66;
$dark50: #878793;
$dark40: #a6a6b2;
$dark30: #d5d5dc;

$light30: #ebebf0;
$light20: #f5f5f7;
$white: #ffffff;

// Contextual Colors
$red30: #ed5349;
$red20: #fbdeb8;
$red10: #fdeefc;
$yellow100: #f8d74b;
$yellow30: #fdf0c7;
$yellow10: #fefaec;
$green30: #65c480;
$green20: #e0f3e6;
$green10: #f0f9f2;
$orange120: #cc7b1f;
$orange100: #f19a37;
$orange20: #fcebd7;
$orange10: #fef5eb;

//Custom Colors
$athensGray: #f8f9fa;
$purpleHeart: #612fdb;
$ghost: #d0d0d5;
$radicalRed: #fe2c55;
$lightGreen: #ecfdf3;
$green: #039855;
$lightBlue: #f4f3ff;
$blue: #5026d9;
