@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  cursor: pointer !important;
  display: flex;
  gap: 10px;

  @media (max-width: 940px) {
    gap: 5px;
    align-items: center;
    justify-content: center;
  }
}

.textWrapper {
}

.tribeName {
  color: rgb(22, 24, 35);
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  margin-top: -2px;
  max-width: 145px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (max-width: 940px) {
    display: none;
  }
}

.leaderName {
  color: rgba(22, 24, 35, 0.75);
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  max-width: 145px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (max-width: 940px) {
    display: none;
  }
}

.selected {
  color: #f0544f;
}
