@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.editWrapper {
  position: relative;
  width: 100%;
}

.textarea {
  width: 100%;
  min-height: 200px;
  resize: vertical;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f8f8fa;
  font-family: inherit;
  color: #333;

  outline: none;

  &:focus {
    border-color: #bbb;
    box-shadow: none;
  }
}

.charCounter {
  font-size: 12px;
  color: #999;
  margin-top: 4px;
  text-align: right;
}

.description {
  color: $dark70;
  font-size: 18px;
  font-weight: 500;
}

.descContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.videos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding-bottom: 0px;

  @include media-breakpoint-down(1190px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include media-breakpoint-down(1053px) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-down(610px) {
    grid-template-columns: 1fr;
  }
}

.editInput {
  color: $dark70;
  font-size: 18px;
  font-weight: 500;

  @media (max-width: 768px) {
    color: white;
    margin-top: 250px;
    margin-left: 20px;
    font-size: 18px;
  }
}
