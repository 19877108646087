@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  border-top: 2px dashed #f5f5f7;
  margin-top: 20px;
  padding-top: 20px;
}

.error {
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.infoWrapper {
  line-height: normal;
}

.btn {
  cursor: pointer !important;
  color: white;
  background-color: #f0544f;
  border: none;
  font-weight: 600;
  height: 36px;
  min-width: 148px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 21px;
  margin-top: 10px;
  width: 100%;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #c04440;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    min-width: 95px;
  }
}

.text {
  color: #000;
  font-size: 14px;
  margin-top: 10px;
}

.link {
  color: blue;
  cursor: pointer;
}

.bidsList {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin-bottom: 10px;
}

.bid {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid rgba(22, 24, 35, 0.75);
  padding: 3px 8px;
  color: rgba(22, 24, 35, 0.75);
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  cursor: pointer;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s;
}

.bid:hover {
  background-color: rgba(22, 24, 35, 0.1);
}

.selected {
  background-color: red;
  color: white;
  border-color: red;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 22px;
  color: #000;
  font-weight: 600;
}

.cancelTitle {
  font-size: 12px;
  color: #878793;
  font-weight: 400;
}

.cancelDesc {
  font-size: 12px;
  color: #000;
  font-weight: 400;
  text-decoration: underline;
  padding-bottom: 1px;
}

.cancelInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.letter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  border: 1px solid #000;
  border-radius: 50%;
  text-align: center;
}
