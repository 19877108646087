@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  display: flex;
  flex-direction: column;
}

.text {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title {
  color: $dark90;
  font-weight: 700;
  font-size: 17px;
  margin-top: 20px;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}

.infoIcon {
  cursor: pointer;
}

.description {
  margin: 10px 0;
  font-size: 15px;
  font-weight: 500;
  color: #666;
}

.videoContainer {
  border: 1px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  text-align: center;
  cursor: pointer;

  &.dragging {
    background-color: #f0f8ff;
    border-color: #007bff;
  }
}

.isVideoUploaded {
  padding: 0px;
}

.videoWrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.uploadText {
  font-size: 15px;
  font-weight: 500;
  color: #666;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 3px;
}

.uploading {
  font-size: 16px;
  color: #666;
}

.previewVideo {
  width: 100%;
  border-radius: 8px;
}

.maxDurationText {
  margin-top: 3px;
  font-size: 13px;
  font-weight: 500;
  color: #666;
}

.hiddenInput {
  display: none;
}

.errorText {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
