.videoContent {
  display: grid;
  grid-template-columns: 65% 35%;
  gap: 20px;
  position: relative;
  padding-top: 70px;
  padding-right: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    overflow-y: auto;
    height: calc(100vh - 20px);
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.video {
  position: relative;
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    display: block;
  }
}

.cross {
  cursor: pointer;
  position: absolute;
  top: 3%;
  left: 20px;
  z-index: 10;
  display: flex;
  background: rgba(84, 84, 84, 0.5);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(270deg);

  &:hover {
    background-color: rgba(24, 24, 24, 0.7);
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.3);
    cursor: not-allowed;
  }
}

.navigationButtons {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  transform: translateY(-50%);

  button {
    background: rgba(84, 84, 84, 0.5);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(270deg);

    &:hover {
      background-color: rgba(24, 24, 24, 0.7);
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.3);
      cursor: not-allowed;
    }

    svg {
      width: 26px;
      height: 26px;
      fill: #fff;
    }
  }
}

.commentsSection {
  overflow-y: auto;
  max-height: 700px;

  @media (max-width: 768px) {
    max-height: calc(100vh - 20px);
  }
}
