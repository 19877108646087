@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  margin-top: 20px;
}

.text {
  display: flex;
  justify-content: space-between;
  a {
    width: 100%;
  }
}

.title {
  color: $dark90;
  font-weight: 700;
  font-size: 17px;

  @include media-breakpoint-down(545px) {
    font-size: 15px;
  }
}

.description {
  color: $dark70;
  font-weight: 500;
  font-size: 15px;

  @include media-breakpoint-down(545px) {
    font-size: 13px;
  }
  span {
    color: #f0544f;
    cursor: pointer;
  }
}
