@mixin title() {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

@mixin heading0() {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

@mixin heading0() {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}
