.tableContainer {
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 8px;
    border: 2px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

.tableTitle {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.tableRow {
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
}

.tableRow:hover {
  background-color: #e0f7fa;
}
