@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  margin: 10px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  padding: 10px;
  font-weight: 500;
  width: 100%;
}

.positive {
  background-color: $lightGreen;
  color: $green;
  border: 1px solid $green;
}

.warning {
  background-color: $lightBlue;
  color: $blue;
  border: 1px solid $blue;
  font-size: 16px;
  @include media-breakpoint-down(545px) {
    font-size: 14px;
  }
}
