.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.28);
    pointer-events: none;
  }
}

.videoPlayer {
  position: relative;
  height: 500px;
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
  background: black;
}

.title {
  font-size: 16px;
  font-weight: 600;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 90%;
  margin-bottom: 42px;
}

.date {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 26px;
}

.challengeOverlay {
  position: absolute;
  bottom: 15px;
  width: 100%;
  color: white;
  z-index: 2;
  text-align: left;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
  padding-left: 25px;
}

.memberName {
  font-size: 24px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.date {
}
