@import 'src/styles/variables.scss';

.container {
  padding-top: 10px;
  display: flex;
  gap: 10px;
  @media (max-width: 768px) {
    // height: 350px;
    padding-top: 0px;
    display: block;
  }
}
.img {
  width: 24px;
  height: 24px;
}

.iconsWrapper {
  margin-top: 10px;
  cursor: pointer;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    margin-right: 0px;
  }
}

.info {
  max-width: 350px;

  @media (max-width: 768px) {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: #161823;

  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.name {
  margin-top: 5px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: $dark100;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.btn {
  cursor: pointer !important;
  color: white;
  background-color: #f0544f;
  border: none;
  font-weight: 600;
  height: 36px;
  min-width: 148px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 21px;
  width: 100%;
  margin-top: 10px;

  &:hover {
    background-color: #c04440;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    min-width: 95px;
  }
}
