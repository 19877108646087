.commentItem {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;

  .commentAuthor {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 8px;
      object-fit: cover;
    }

    .authorName {
      font-weight: 700;
      font-size: 14px;
      color: #101010;
    }
  }

  .commentContent {
    p {
      margin: 5px 0;
      font-size: 14px;
      line-height: 1.4;
      color: #444;
    }
  }

  .repliesButton {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 12px;
    margin-top: 10px;
  }

  .replies {
    margin-top: 10px;
    padding-left: 20px;
  }
}

.commentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popover {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 120px;
}

.popoverEdit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  padding: 10px;

  &:hover {
    background: #e0dfdf;
  }
}

.popoverDelete {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  color: red;
  cursor: pointer;
  padding: 10px;

  &:hover {
    background: #e0dfdf;
  }
}

.replyInput {
  padding-top: 15px;
}

.likeCount {
  color: black;
}

.date {
  font-size: 12px;
  color: #888;
}

.actions {
  display: flex;
  align-items: center;

  .likeButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;

    svg {
      color: #ff5252;
      margin-right: 3px;
    }

    span {
      font-size: 12px;
      color: #888;
    }
  }

  .replyButton {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      color: #888;
    }
  }
}

.commentImage {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  object-fit: cover;
}

.commentMeta {
  font-size: 12px;
  color: #888;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.inputWrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.commentInput {
  width: 100%;
  padding: 10px 40px 10px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff;
  }
}

.postButton {
  text-align: center;
  width: 15%;
  color: white;
  background-color: #f0544f;
  font-weight: 600;
  border-radius: 4px;
  padding: 7px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.commentVideo {
  width: -webkit-fill-available;
  height: 300px;
}
